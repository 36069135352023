import React from 'react'

export const Icon = ({
  position,
  margin = 'm-0',
  name,
  height = '80',
  width = '80',
}) => {
  return (
    <img
      className={position ? `${margin} position-${position}` : `${margin}`}
      src={`/img/icons/icon-${name}.png`}
      width={width}
      height={height}
      alt={`Icon: ${name}`}
    />
  )
}
