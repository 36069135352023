import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { Icon } from '../../../common/icon'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage12() {
  const question = {
    title: 'Frage 14 von 35',
    headline: '',
    subline: 'Arbeitsabläufe vs. Herausforderungen',
  }

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-purple">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="purple"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="range"
              color="turkis"
              from="Ich mag gleichbleibende, feste Arbeitsabläufe"
              to="Ich stelle mich bei der Arbeit gern neuen Herausforderungen"
              index={14}
            />
          </div>
        </section>

        <MatchingNavigation
          color="purple"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-15`}
          prev={`${AREA_CARE_PATH}/spielen/frage-13`}
          index={14}
        />

        <Icon position="4" name="signpost" />
      </main>
    </>
  )
}
